import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import { connect } from "./redux/blockchain/blockchainActions";
import * as ver from "./setupWeb.js";

import animation_background from './images/animation_background.png';
import animation_pizza_teacher from './images/animation_pizza_teacher.png';
import animation_board from './images/animation_board.png';
import animation_door_left from './images/animation_door_left.png';
import animation_door_right from './images/animation_door_right.png';

import animation_pizza1 from './images/animation_pizza1.png';
import animation_pizza2 from './images/animation_pizza2.png';
import animation_pizza3 from './images/animation_pizza3.png';
import animation_pizza4 from './images/animation_pizza4.png';
import animation_pizza5 from './images/animation_pizza5.png';
import animation_pizza6 from './images/animation_pizza6.png';
import animation_roadmap1 from './images/animation_roadmap1.png';
import animation_roadmap2 from './images/animation_roadmap2.png';
import animation_roadmap3 from './images/animation_roadmap3.png';
import animation_roadmap4 from './images/animation_roadmap4.png';
import animation_roadmap5 from './images/animation_roadmap5.png';
import animation_roadmap6 from './images/animation_roadmap6.png';

import telegram_icon from './images/telegramsvg.svg';
import twitter_icon from './images/twittersvg.svg';


import isotipo2 from './images/isotipo2.png';
import faq from './images/faq.png';
import team1 from './images/team1.png';
import team2 from './images/team2.png';
import teamgif from './images/teamgif.gif'
import lore2 from './images/cap.jpg';
import pixelmart from './images/pixelmart.svg';
import mantra_logo from './images/mantra_logo.png';
import dogechain from './images/dogechain.png';
import image from './images/image.webp';

import './Web.css';
import './Header.css';
import './AnimationRoadmap.css';
import './Normalize.css';
import './Team.css';
import Aos from "aos";
import "aos/dist/aos.css";

import * as s from "./globalStyles.js";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: orange;
  padding: 10px;
  font-weight: bold;
  color: black;
  width: 100px;
  cursor: url(/config/images/cursor-pointer-santas-32.png), pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: orange;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: black;
  width: 30px;
  height: 30px;
  cursor: url('../styles/images/cursor-pointer-santas-32.png'), pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoFoodmoon = styled.img`
  align: left;
  width: 15%;
  height: 15%;
`;

export const StyledImg = styled.img`
  background-color: var(--darkboxes);
  padding: 24;
  border-radius: 24;
  border: 4px var(--boxes);
  box-shadow:
		0 0 5px rgba(0, 124, 9, 0.95),
		0 0 10px rgba(0, 124, 9, 0.95),
		0 0 20px rgba(0, 124, 9, 0.95);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
  animation-name: dropFly;
	animation-duration: 1.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
`;

export const StyledLink = styled.a`
  color: orange;
  text-decoration: underline;
`;

function Web() {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    // wait until DOM has been rendered
    useEffect(() => {
        const element = ref.current;
        
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element.querySelector(".roadmap-initial"),
                markers: false,
                scrub: true,
                pin: true,
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "+=200", // end after scrolling 500px beyond the start
            }
        });   

        tl.add('start');

        tl.to(element.querySelector("#animation_door_left"), { x: -25 })
        .to(element.querySelector("#animation_door_right"), { x: 25 });

        tl.to(element.querySelector("#animation_door_left"), { x: -50 })
        .to(element.querySelector("#animation_door_right"), { x: 50 });

        tl.to(element.querySelector("#animation_door_left"), { x: -75 })
        .to(element.querySelector("#animation_door_right"), { x: 75 });

        tl.to(element.querySelector("#animation_door_left"), { x: -100 })
        .to(element.querySelector("#animation_door_right"), { x: 100 });

        tl.to(element.querySelector("#animation_door_left"), { x: -125 })
        .to(element.querySelector("#animation_door_right"), { x: 125 });

        tl.to(element.querySelector("#animation_door_left"), { x: -150 })
        .to(element.querySelector("#animation_door_right"), { x: 150 });

        tl.to(element.querySelector("#animation_door_left"), { x: -175 })
        .to(element.querySelector("#animation_door_right"), { x: 175 });

        tl.to(element.querySelector("#animation_door_left"), { x: -200 })
        .to(element.querySelector("#animation_door_right"), { x: 200 });

        tl.to(element.querySelector("#animation_door_left"), { x: -225 })
        .to(element.querySelector("#animation_door_right"), { x: 225 });

        tl.to(element.querySelector("#animation_door_left"), { x: -250 })
        .to(element.querySelector("#animation_door_right"), { x: 250 });

        tl.to(element.querySelector("#animation_door_left"), { x: -275 })
        .to(element.querySelector("#animation_door_right"), { x: 275 });

        tl.to(element.querySelector("#animation_door_left"), { x: -300 })
        .to(element.querySelector("#animation_door_right"), { x: 300 });

        tl.to(element.querySelector("#animation_door_left"), { x: -325 })
        .to(element.querySelector("#animation_door_right"), { x: 325 });

        tl.to(element.querySelector("#animation_door_left"), { x: -350 })
        .to(element.querySelector("#animation_door_right"), { x: 350 });

        tl.to(element.querySelector("#animation_door_left"), { x: -375 })
        .to(element.querySelector("#animation_door_right"), { x: 375 });

        tl.to(element.querySelector("#animation_door_left"), { x: -400 })
        .to(element.querySelector("#animation_door_right"), { x: 400 });

        tl.to(element.querySelector("#animation_door_left"), { x: -425 })
        .to(element.querySelector("#animation_door_right"), { x: 425 });

        tl.to(element.querySelector("#animation_door_left"), { x: -450 })
        .to(element.querySelector("#animation_door_right"), { x: 450 });

        tl.to(element.querySelector("#animation_door_left"), { x: -475 })
        .to(element.querySelector("#animation_door_right"), { x: 475 });

        tl.to(element.querySelector("#animation_door_left"), { x: -500 })
        .to(element.querySelector("#animation_door_right"), { x: 500 });

        tl.to(element.querySelector("#animation_door_left"), { x: -525 })
        .to(element.querySelector("#animation_door_right"), { x: 525 });

        tl.to(element.querySelector("#animation_door_left"), { x: -550 })
        .to(element.querySelector("#animation_door_right"), { x: 550 });

        tl.to(element.querySelector("#animation_door_left"), { x: -575 })
        .to(element.querySelector("#animation_door_right"), { x: 575 });

        tl.to(element.querySelector("#animation_door_left"), { x: -600 })
        .to(element.querySelector("#animation_door_right"), { x: 600 });

        tl.to(element.querySelector("#animation_door_left"), { x: -625 })
        .to(element.querySelector("#animation_door_right"), { x: 625 });

        tl.to(element.querySelector("#animation_door_left"), { x: -650 })
        .to(element.querySelector("#animation_door_right"), { x: 650 });

        tl.to(element.querySelector("#animation_door_left"), { x: -675 })
        .to(element.querySelector("#animation_door_right"), { x: 675 });

        tl.to(element.querySelector("#animation_door_left"), { x: -700 })
        .to(element.querySelector("#animation_door_right"), { x: 700 });

        tl.to(element.querySelector("#animation_door_left"), { x: -725 })
        .to(element.querySelector("#animation_door_right"), { x: 725 });

        tl.to(element.querySelector("#animation_door_left"), { x: -750 })
        .to(element.querySelector("#animation_door_right"), { x: 750 });

        tl.to(element.querySelector("#animation_door_left"), { x: -775 })
        .to(element.querySelector("#animation_door_right"), { x: 775 });

        tl.to(element.querySelector("#animation_door_left"), { x: -800 })
        .to(element.querySelector("#animation_door_right"), { x: 800 });


        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: element.querySelector(".roadmap-content"),
                markers: false,
                scrub: true,
                pin: true,
                start: "top top", // when the top of the trigger hits the top of the viewport
            }
        });

        tl2.add('start');
        
        
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap1"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza1"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap1"), { opacity: 0, duration: 2, delay: 2 }, 'start');

        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap2"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza2"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap2"), { opacity: 0, duration: 2, delay: 2 }, 'start');

        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap3"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza3"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap3"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap4"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza4"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap4"), { opacity: 0, duration: 2, delay: 2 }, 'start');

        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap5"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza5"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap5"), { opacity: 0, duration: 2, delay: 2 }, 'start');

        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".roadmap6"), { opacity: 1, duration: 2, delay: 2 });
        tl2.to(element.querySelector(".pizza6"), { opacity: 0, duration: 2, delay: 2 }, 'start');
        tl2.to(element.querySelector(".roadmap6"), { opacity: 0, duration: 2, delay: 2 }, 'start');

        

        const tl3 = gsap.timeline({
          scrollTrigger: {
              trigger: element.querySelector(".header-animation-new"),
              markers: false,
              scrub: true,
              pin: true,
              start: "top top", // when the top of the trigger hits the top of the viewport
          }
      });

      if (screen.width >= 0 && screen.width <= 800) {
          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.9 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.9 });
  
          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.8 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.8 });
  
          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.7 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.7 });

          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.6 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.6 });

          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.5 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.5 });

          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.4 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.4 });

          tl3.to(element.querySelector(".header-animation-left-new"), { opacity: 0.3 })
          .to(element.querySelector(".header-animation-right-new"), { opacity: 0.3 });
      } else {
          tl3.to(element.querySelector(".header-animation-left-new"), { x: -100, opacity: 0.9 })
          .to(element.querySelector(".header-animation-right-new"), { x: 100, opacity: 0.9  });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -125, opacity: 0.8  })
          .to(element.querySelector(".header-animation-right-new"), { x: 125, opacity: 0.8 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -150, opacity: 0.7 })
          .to(element.querySelector(".header-animation-right-new"), { x: 150, opacity: 0.7 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -175, opacity: 0.6 })
          .to(element.querySelector(".header-animation-right-new"), { x: 175, opacity: 0.6 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -200, opacity: 0.5 })
          .to(element.querySelector(".header-animation-right-new"), { x: 200, opacity: 0.5 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -225 })
          .to(element.querySelector(".header-animation-right-new"), { x: 225 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -250 })
          .to(element.querySelector(".header-animation-right-new"), { x: 250 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -275 })
          .to(element.querySelector(".header-animation-right-new"), { x: 275 });

          tl3.to(element.querySelector(".header-animation-left-new"), { x: -300 })
          .to(element.querySelector(".header-animation-right-new"), { x: 300 });
      }
  

      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.1 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.2 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.3 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.4 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.5 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.6 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.7 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.8 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 0.9 });
      tl3.to(element.querySelector(".header-animation-center-new"), { opacity: 1 }); 
  });

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click mint to buy your DogeGang NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    STATUS: false,
  });

  const claimNFTs = () => {
    let verified = false;
    if (CONFIG.STATUS) {
      ver.ww.forEach(element => {
        if (element.toLowerCase() == blockchain.account) {
          verified = true;
        }
      });
    } else {
      verified = true;
    }

    if (verified) {
      if (Number(data.totalSupply) <= 6666) {
        if (mintAmount >= 1 && mintAmount <= 10) {
          let cost = CONFIG.WEI_COST;
          let gasLimit = CONFIG.GAS_LIMIT;
          let totalCostWei = String(cost * mintAmount);
          let totalGasLimit = String(gasLimit * mintAmount);
          if (mintAmount == 2) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 3) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 4) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 5) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 6) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 7) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 8) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 9) {
            totalGasLimit = String(300000);
          }
          else if (mintAmount == 10) {
            totalGasLimit = String(300000);
          }
          setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
          setClaimingNft(true);
          blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
              gasLimit: String(totalGasLimit),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
              value: totalCostWei,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback("Sorry, something went wrong. Please check the hash of your transaction from dogechain explorer to get more details.");
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `Congrats, the ${CONFIG.NFT_NAME} is yours! Go visit ${CONFIG.MARKETPLACE_LINK} to view it.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
          });
        }
        else {
          alert("You can't mint more than 10 NFTs.");
        }
      }
      else {
        alert("The maximum amount (6666) has been minted.");
      }
    } else {
      alert("You are not in the whitelist, wait for the public sale.");
    }
  };

  
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
      alert("You can't mint less than 1 NFT.");
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
      alert("You can't mint more than 10 NFTs.");
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getCost = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);





    return (
        <div className='container-full' ref={ref}>
            
            <header>
                <div className="header-menu-new">
                    <img className="header-logo-new" alt={"DogeGang"} src={isotipo2}></img>

                    <nav className="header-social-new">
                        <ul>
                            <li className="header-social-new-li"><a className="header-social-new-a" href="https://t.me/dogegangnft" target="_blank"><img className="header-social-img-new" src={telegram_icon}></img></a></li>
                            <li className="header-social-new-li"><a className="header-social-new-a" href="https://twitter.com/dogegangnft" target="_blank"><img className="header-social-img-new" src={twitter_icon}></img></a></li>
                        </ul>
                        
                    </nav>
                </div>
                <div data-aos="zoom-in" className="header-options-new">
                    <nav>
                        <ul>
                            <li className="header-options-new-li-mint"><a className="option-mint" href="#mint">Mint</a></li>
                            <li className="header-options-new-li"><a className="header-options-new-a" href="https://dogegang-rarity.herokuapp.com/">Rarity</a></li>
                            <li className="header-options-new-li"><a className="header-options-new-a" href="#team">Team</a></li>
                            <li className="header-options-new-li"><a className="header-options-new-a" href="#roadmap">Roadmap</a></li>
                            <li className="header-options-new-li"><a className="header-options-new-a" href="#faq">FAQ</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="header-animation-new">
                    <div className="header-animation-left-new">
                        
                    </div>
                    <div className="header-animation-right-new">
                        
                    </div>
                    <div className="header-animation-center-new">
                        
                    </div>
                    <div className="header-animation-center-new">
                        
                    </div>
                </div>
            </header>

            <section id="mint" className='lore-content'>
            <div data-aos="zoom-in-down" className='lore-content-text content-color'>
                    {
                    <h1 className='titles'>DOGE GANG MINT <br></br>
                    MINTED: {data.totalSupply} / {CONFIG.MAX_SUPPLY}<br></br>
                    <br></br>
                    1 DG COSTS {CONFIG.DISPLAY_COST} DOGE</h1>

                    
                    }
                    <br></br>

                    <div style={{ textAlign: "center", marginTop: "25px", marginBottom: "15px" }}>
                      <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                        DogeGang Contract Address
                      </StyledLink>
                    </div>
                    
                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "white" }}
                      >
                        The sale has ended.
                      </s.TextTitle>
                      <s.TextDescription
                        style={{ textAlign: "center", color: "white" }}
                      >
                        You can still find {CONFIG.NFT_NAME} on
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{ textAlign: "center", color: "white" }}
                      >
                        
                      </s.TextDescription>
                      <s.SpacerSmall />
                      {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                              Click to go to Mantra marketplace
                            </StyledLink>
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <a className='button-lore mint'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </a>

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "white",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledButton>
                    </s.Container>
                    
                  </>
                )}
              </>
            )}
            

      
                </div>
                <div data-aos="zoom-in-up" className='lore-content-image'>
                    <img className='image-right' alt={"DogeGang Mint"} src={teamgif}></img>
                </div>
            </section>

            
            
            <section id="lore" className='lore-content'>
                <div data-aos="zoom-in-up" className='lore-content-image'>
                    <img className='images image-right' alt={"DogeGang Lore"} src={lore2}></img>
                </div>
                <div data-aos="zoom-in-down" className='lore-content-text content-color'>
                    <h1 className='titles'>FOR THE MEMES</h1>
                    <p className='paragraphs'>Once upon a time frens, there was a group of crypto enthusiasts who called themselves the "Dogegang." 
                    They believed in the power of the Dogecoin cryptocurrency and the utilities Dogechain brings, as well as its ability to bring people together in a fun and lighthearted way.
                    They also loved the Shiba Inu dog, the mascot of Dogecoin, and wanted to create something special to honor it on Dogechain.
                    </p>
                    <p className='paragraphs'>One day, Mr. Doge and had an idea. 
                    To create a limited-edition set of NFTs featuring unique, hand-drawn PFP artwork of the Shiba Inu dog. 
                    He decided to have 6,666 pieces of these digital collectibles, a nod to the total supply of Dogecoin. 
                    But, they also wanted to create a special set of 18 DogeGang NFTs that would be truly rare and one-of-a-kind.
                    </p>
                    <p className='paragraphs'>We believe that the Dogechain is the perfect choice for this project, and we are excited to be a part of this journey.
We are confident that we will have a bright future for the Dogechain, with its projects and communities.</p>
                    <p className='paragraphs'>-Over and out. Mr. Doge & Pop</p>
                    
                </div>
            </section>


            

            <section id="roadmap" className="roadmap-initial">
                <img id="animation_background" alt={"DogeGang"} className='back' src={animation_background}></img>
                <img data-aos="zoom-in-down" alt={"DogeGang"} data-aos-offset="500"
     data-aos-duration="800" id="animation_board" className='board' src={animation_board}></img>
                <img data-aos="zoom-in-up" alt={"DogeGang"} data-aos-offset="500"
     data-aos-duration="800" id="animation_pizza_teacher" alt={"DogeGang"} className='teacher' src={animation_pizza_teacher}></img>
                <img id="animation_door_left" alt={"DogeGang"} className='door' src={animation_door_left}></img>
                <img id="animation_door_right" alt={"DogeGang"}className='door' src={animation_door_right}></img>
            </section>
            
            <div className='roadmap-content'>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza1' src={animation_pizza1}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza2' src={animation_pizza2}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza3' src={animation_pizza3}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza4' src={animation_pizza4}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza5' src={animation_pizza5}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='pizza pizza6' src={animation_pizza6}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap1' src={animation_roadmap1}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap2' src={animation_roadmap2}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap3' src={animation_roadmap3}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap4' src={animation_roadmap4}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap5' src={animation_roadmap5}></img>
                <img data-aos="zoom-in" alt={"DogeGang"} className='roadmap roadmap6' src={animation_roadmap6}></img>
            </div>

            <section id="team" className='team-content'>
            <h1 data-aos="zoom-in" className="effects">Team</h1>
                <div className='lore-content-text team-grid'>
                    <div data-aos="zoom-in-up" className="col-md-3 first-team">
                      <div className="team-member">
                        <figure className="team-box effect">
                        </figure>
                        
                        <ul className="fixed-team">
                          
                        </ul>
                      </div>
                    </div>


                    <div data-aos="zoom-in-down" className="col-md-3 four-team">
                      <div className="team-member">
                        <figure className="team-box effect">
                          <img className="img-responsive img-lore" alt={"FoodMoon Team"} src={team2} />
                        </figure>
                        <h4 className="main-team-name">Mr Doge</h4>
                        <p className="main-team-role">Founder / Dev </p>
                      </div>
                    </div>

                    <div data-aos="zoom-in-down" className="col-md-3 five-team">
                      <div className="team-member">
                        <figure className="team-box effect">
                          <img className="img-responsive img-lore" alt={"FoodMoon Team"} src={team1} />
                        </figure>
                        <h4 className="main-team-name">Pop Doge</h4>
                        <p className="main-team-role">Community manager</p>
                      </div>
                    </div>
                    
                </div>
            </section>

            <div className='faq-content'>
                <section id="faq" className="faq-section">
                <div className="faq-title text-center pb-3">
                    <h2 data-aos="zoom-in" className="effects">FAQ</h2>
                </div>

                <div data-aos="zoom-in-up" className="faq-question">
                    <input type="checkbox" id="question1" name="q"  className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question1" className="question">
                    What is DogeGang NFT?
                    </label>
                    <div className="answers">
                    <p>DogeGang NFT is a collection of 6666 gang doges including 18 1/1 Rare Doges. Living on Dogechain</p>
                    
                    </div>
                </div>

                <div data-aos="zoom-in-down">
                    <input type="checkbox" id="question2" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question2" className="question">
                    What is DogeGang's total supply?
                    </label>
                    <div className="answers">
                    <p>6666</p>
                    
                    </div>
                </div>

                <div data-aos="zoom-in-up">
                    <input type="checkbox" id="question3" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question3" className="question">
                    Where can I mint?
                    </label>
                    <div className="answers">
                    <p>You can only mint on Dogechain, dogegang.dog, mint.dogegang.dog or Mantra marketplace. You will be able to 
                      mint using wDOGE and a MetaMask wallet.</p>
                    
                    </div>
                </div>
                
                <div data-aos="zoom-in-down">
                    <input type="checkbox" id="question4" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question4" className="question">
                    Which blockchain do you use?
                    </label>
                    <div className="answers">
                    <p>The Dogechain blockchain. With very low gas fees.</p>
                    
                    </div>
                </div>
                
                <div data-aos="zoom-in-up">
                    <input type="checkbox" id="question5" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question5" className="question">
                    When can I buy a DogeGang NFT?
                    </label>
                    <div className="answers">
                    <p>You can buy from either Mantra, Oasis or Pixelmart</p>
                    
                    </div>
                </div>
                
                    
                <div data-aos="zoom-in-up">
                    <input type="checkbox" id="question7" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question7" className="question">
                    Will there be any Utility for DogeGang NFT?
                    </label>
                    <div className="answers">
                    <p>There will be future utility for DogeGang NFT announcements is to be made in coming future </p>
                    
                    </div>
                </div>

                <div data-aos="zoom-in-down">
                    <input type="checkbox" id="question8" name="q" className="questions"/>
                    <div className="plus">+</div>
                    <label htmlFor="question8" className="question">
                    Will there be any giveaways?
                    </label>
                    <div className="answers">
                    <p>Yes, we have weekly givaways aswell as surprise free mints and milestone prizes.</p>
                    
                    </div>
                </div>
                <img className='images faq-image' alt={"DogeGang Faq"} src={faq}></img>
                </section>
            </div>

            <footer>
                <div className="header-options-new footer-fix">
                    <img className="header-logo-new fixed-footer" src={isotipo2}></img>
                    <nav className="options-footer">
                        <ul>
                            <li className="header-social-new-li"><a className="header-social-new-a" href="https://t.me/dogegangnft" target="_blank"><img className="header-social-img-new" src={telegram_icon}></img></a></li>
                            <li className="header-social-new-li"><a className="header-social-new-a" href="https://twitter.com/dogegangnft" target="_blank"><img className="header-social-img-new" src={twitter_icon}></img></a></li>
                        </ul>
                    </nav>
                    <li className="footer-options-new-li"><a className="footer-options-new-a" href="https://explorer.dogechain.dog/address/0x011f614b13ef08B905A2Ae68443f6344CCE32046" target="_blank">Contract Address</a></li>
                    <br></br>
                    <a href="https://app.withmantra.com/auctions/collection/0x011f614b13ef08b905a2ae68443f6344cce32046?chain_id=2000" target="_blank"> <img style={{ marginLeft: "20px" }} src={mantra_logo} alt="" width="128" /></a>
                    <br></br>
                    <a href="https://dogechain.dog/" target="_blank"><img style={{ marginTop: "px" }} src={dogechain} alt="" width="128" /></a>
                    <br></br>
                    <a href="https://thedruggedhuskies.io/" target="_blank"><img style={{ marginTop: "px" }} src={image} alt="" width="128" /></a>
                    <p className="text-footer">©2023 DOGEGANG NFT. All rights reserved.</p>
                    <p className="text-footer disclaimer"></p>
                </div>
            </footer>
        </div>
    );
}

export default Web;
